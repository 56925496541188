 ::-webkit-scrollbar {
   width: 5px;
 }

 ::-webkit-scrollbar-track {
   background: #fff;
 }

 ::-webkit-scrollbar-thumb {
   background: #000;
   border-radius: 0;
 }

 :root {
   --text-color: #48494e;
   --paragraph-color: #777c87;
   --heading: #272063; // #014073; // #090f32;
   --prime-one: #ff8c24;
   --prime-two: #ff733b;
   --prime-three: #6a45ff;
   --prime-four: #ffcd45;
   --prime-five: #007bff;
   --prime-six: #ffd338;
   --prime-seven: linear-gradient(-90deg, #ff7df2 0%, #ff275b 100%);
   --prime-eight: #ffed4e;
   --prime-nine: #ff1292;
   --prime-ten: #0056b3; // #1890ff; // #ff3294;
   --prime-eleven: #ffce4c;
   --prime-twelve: #ffe500;

   --main-blue: #90caf9;
   --main-white: #fff;
   --main-grey: rgba(255, 255, 255, 0.23);
   --main-dark-grey: #121212;
   --main-ease: cubic-bezier(.65, .05, .36, 1);
 }

 ::selection {
   background-color: #2a2a2a;
   color: #fff;
 }

 /*** 
====================================================================
  Loading Transition
====================================================================

 ***/

 .loaded .animation-preloader {
   opacity: 0;
   transition: 0.3s ease-out;
 }

 @keyframes spinner {
   to {
     transform: rotateZ(360deg);
   }
 }

 @keyframes letters-loading {

   0%,
   75%,
   100% {
     opacity: 0;
     transform: rotateY(-90deg);
   }

   25%,
   50% {
     opacity: 1;
     transform: rotateY(0deg);
   }
 }

 /*^^^^^^^^^^^^^^^^^^^^ Global Prefix ^^^^^^^^^^^^^^^^^^^^^*/
 .main-page-wrapper {
   overflow-x: hidden;
 }

 body {
   font-weight: normal;
   font-size: 16px;
   position: relative;
   color: var(--text-color);
 }

 .h1,
 h1,
 .h2,
 h2,
 .h3,
 h3,
 .h4,
 h4,
 .h5,
 h5,
 .h6,
 h6 {
   color: var(--heading);
 }

 .h1,
 h1 {
   font-size: 80px;
   line-height: 1.18em;
   /*done*/
 }

 .h2,
 h2 {
   font-size: 58px;
   line-height: 1.29em;
   letter-spacing: -1px;
   /*done*/
 }

 .h3,
 h3 {
   font-size: 32px;
   /*done*/
 }

 .h4,
 h4 {
   font-size: 24px;
   line-height: 1.54em;
   /*done*/
 }

 .h5,
 h5 {
   font-size: 22px;
   line-height: 1.45em;
   /*done*/
 }

 .h6,
 h6 {
   font-size: 18px;
   /*done*/
 }

 p {
   line-height: 1.87em;
 }

 ::-webkit-input-placeholder {
   color: rgba(0, 0, 0, 0.6);
 }

 :-ms-input-placeholder {
   color: rgba(0, 0, 0, 0.6);
 }

 ::placeholder {
   color: rgba(0, 0, 0, 0.6);
 }

 /*^^^^^^^^^^^^^^^^^^^^ Prefix Classes ^^^^^^^^^^^^^^^^^^^^^*/
 .tran3s,
 .svg path {
   transition: all 0.3s ease-in-out;
 }

 .tran4s {
   transition: all 0.4s ease-in-out;
 }

 .tran5s {
   transition: all 0.5s ease-in-out;
 }

 .tran6s {
   transition: all 0.6s ease-in-out;
 }

 .fs-13 {
   font-size: 13px;
 }

 .fs-14 {
   font-size: 14px;
 }

 .fs-15 {
   font-size: 15px;
 }

 .fs-16 {
   font-size: 16px;
 }

 .fs-17 {
   font-size: 17px;
 }

 .fs-18 {
   font-size: 18px;
 }

 .fs-20 {
   font-size: 20px;
 }

 .fw-500 {
   font-weight: 500;
 }

 .fw-400 {
   font-weight: 400;
 }

 .tx-dark {
   color: #000;
 }

 .p-30 {
   padding: 30px;
 }

 .font-recoleta {
   font-family: "Recoleta";
 }

 .text-lg {
   font-size: 18px;
   line-height: 1em;
 }

 .style-none {
   list-style: none;
   padding-left: 0;
   margin-bottom: 0;
 }

 .shapes {
   position: absolute;
   z-index: -1;
 }

 .border-top {
   border-top: 1px solid #f1f1f1 !important;
 }

 .border-bottom {
   border-bottom: 1px solid #f1f1f1 !important;
 }

 .zn2 {
   z-index: 2;
 }

 #contact-form .input-group-meta {
   position: relative;
 }

 .help-block {
   left: 0;
   bottom: -17px;
   font-size: 15px;

   // color: #ff4d44;
   &.with-errors {
     color: #d45656;
   }
 }

 #contact-form .form-group .help-block ul {
   margin-bottom: 0;
 }

 .dark-bg-one {
   background: #151937;
 }

 /*^^^^^^^^^^^^^^^^^^^^ Scroll Top ^^^^^^^^^^^^^^^^^^^^^*/
 .scroll-top {
   width: 60px;
   height: 60px;
   line-height: 57px;
   position: fixed;
   bottom: 40px;
   right: 50px;
   z-index: 99;
   text-align: center;
   color: #fff;
   font-size: 30px;
   cursor: pointer;
   border-radius: 50%;
   background: var(--prime-ten);
   transition: all 0.3s ease-in-out;
   border: 2px solid #fff;
 }

 .scroll-top:after {
   position: absolute;
   z-index: -1;
   content: "";
   top: 100%;
   left: 5%;
   height: 10px;
   width: 90%;
   opacity: 1;
   background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
 }

 .scroll-top svg {
   fill: #fff;
 }

 /*^^^^^^^^^^^^^^^^^^^^ Section Title ^^^^^^^^^^^^^^^^^^^^^^*/
 .title-style-one .sc-title {
   font-size: 16px;
   letter-spacing: 2px;
   color: rgba(0, 0, 0, 0.3);
   margin-bottom: 8px;
 }

 .title-style-one .main-title span {
   display: inline-block;
   position: relative;
   z-index: 1;
 }

 .title-style-one .main-title span:before {
   content: "";
   position: absolute;
   left: 4px;
   bottom: 10px;
   width: 98%;
   height: 12px;
   background: rgba(255, 139, 37, 0.4);
   z-index: -1;
 }

 .title-style-five .sc-title {
   font-size: 16px;
   color: #bebebe;
   letter-spacing: 4px;
   padding-bottom: 20px;
 }

 .detail-blog-sec .sc-title-two,
 .title-style-five .sc-title-two {
   font-size: 17px;
   color: var(--prime-ten);
   padding-left: 40px;
   margin-bottom: 10px;
 }

 .detail-blog-sec .sc-title-two:before,
 .title-style-five .sc-title-two:before {
   content: "";
   position: absolute;
   width: 24px;
   height: 1px;
   background: var(--prime-ten);
   left: 0;
   top: 14px;
 }

 .title-style-five .main-title {
   font-size: 45px;
   line-height: 1.25em;
 }

 .title-style-five .main-title>span {
   z-index: 1;
 }

 .title-style-five .main-title .mark-bg {
   position: absolute;
   left: 0;
   bottom: 17px;
   width: 100%;
   height: 13px;
   z-index: -1;
 }

 .title-style-six .sc-title {
   font-family: "Noteworthy Light";
   font-size: 20px;
   letter-spacing: 3px;
   padding-bottom: 6px;
   text-transform: uppercase;
 }

 .title-style-six .sc-title-two {
   font-size: 15px;
   letter-spacing: 2px;
   padding-bottom: 8px;
   color: var(--prime-ten);
 }

 .title-style-six .main-title {
   font-size: 62px;
   line-height: 1.32em;
 }

 .title-style-fourteen .sc-title {
   font-size: 15px;
   letter-spacing: 4px;
   text-transform: uppercase;
   color: var(--prime-ten);
   padding-bottom: 16px;
 }

 .title-style-fourteen .main-title {
   font-size: 86px;
   letter-spacing: 0;
   line-height: 1.17em;
 }

 .title-style-fourteen .main-title img {
   position: absolute;
   bottom: 9px;
   left: 0;
   z-index: -1;
 }

 .title-style-fourteen .sc-title {
   font-size: 15px;
   letter-spacing: 4px;
   text-transform: uppercase;
   color: var(--prime-ten);
   padding-bottom: 16px;
 }

 .title-style-fourteen .main-title {
   font-size: 86px;
   letter-spacing: 0;
   line-height: 1.17em;
 }

 .title-style-fourteen .main-title img {
   position: absolute;
   bottom: 9px;
   left: 0;
   z-index: -1;
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Theme Button ^^^^^^^^^^^^^^^^^^^^^^*/

 body .btn-twentyOne {
   line-height: 50px;
   padding: 0 35px;
   border-radius: 30px;
   background: var(--prime-ten);
   color: #fff;
   letter-spacing: -0.36px;
 }

 body .btn-twentyOne:hover {
   background: #000;
 }

 body .btn-twentyTwo {
   line-height: 55px;
   padding: 0 42px;
   border-radius: 7px;
   background: var(--prime-ten);
   color: #fff;
   letter-spacing: -0.2px;
 }

 body .btn-twentyTwo:hover {
   background: #000;
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Theme Menu ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .theme-main-menu {
   position: absolute;
   z-index: 99;
   top: 0;
   left: 0;
   right: 0;
   // padding: 20px 60px;
   transition: all 0.4s ease-out;
   background-color: var(--prime-ten);
 }

 .theme-main-menu.sticky-menu.fixed {
   position: fixed;
   z-index: 990;
   // padding-top: 12px;
   // padding-bottom: 12px;
   box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
 }

 .theme-main-menu .logo {
   min-height: 50px;
   min-width: 95px !important;
   display: flex;
   align-items: center;

   img {
     height: 100px;
   }
 }

 .theme-main-menu .lang-dropdown:before {
   content: "";
   position: absolute;
   width: 1px;
   height: 45px;
   background: rgba(216, 216, 216, 0.2);
   left: -25px;
   top: 50%;
   transform: translateY(-50%);
 }

 .theme-main-menu .lang-dropdown button {
   font-weight: 500;
   //  width: 0;
   color: #fff;
   font-size: 22px;
   border: none;
   padding: 0 15px 0 0;
   line-height: initial;
   position: relative;
 }

 .theme-main-menu .lang-dropdown button:after {
   display: none;
 }

 .theme-main-menu .lang-dropdown .dropdown-menu {
   border: 1px solid rgba(0, 0, 0, 0.05);
   padding: 0;
   min-width: 180px;
 }

 .theme-main-menu .lang-dropdown .dropdown-item {
   line-height: 35px;
   padding: 0 15px;
   font-size: 15px;
   transition: all 0.3s ease-in-out;
 }

 .theme-main-menu .lang-dropdown .dropdown-item:hover {
   //  background: var(--prime-four);
   background: gray;
   color: #fff !important;
 }


 /*^^^^^^^^^^^^^^^^^^^^^ Footer One ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .theme-basic-footer .footer-title {
   font-size: 24px;
   margin-bottom: 10px;
   ;
 }

 .theme-basic-footer .footer-nav-link a {
   font-size: 18px;
   line-height: 35px;
   transition: all 0.3s ease-in-out;
   color: #fff;
 }

 .theme-basic-footer .form-widget h6 {
   font-size: 20px;
 }

 .theme-basic-footer .form-widget form {
   height: 40px;
 }

 .theme-basic-footer .form-widget form input {
   width: 100%;
   height: 100%;
   border: none;
   padding: 0 121px 0 12px;
 }

 .theme-basic-footer .form-widget form button {
   font-size: 14px;
   top: 6px;
   right: 6px;
   bottom: 6px;
   width: 90px;
   color: #fff;
   background: var(--prime-ten);
 }

 .theme-basic-footer .form-widget form button:hover {
   background: var(--prime-six);
   color: #000;
 }


 /*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .slick-dots li {
   display: inline-block;
 }

 .slick-dots li button:before {
   font-size: 10px !important;
 }

 .slick-dots li.slick-active button:before {
   color: var(--prime-ten) !important;
 }

 //  .slick-dots li button {
 //    text-indent: -50000px;
 //    width: 8px;
 //    height: 8px;
 //    border-radius: 50%;
 //    background: rgba(0, 0, 0, 0.1);
 //    margin: 0 5px;
 //    transition: all 0.3s ease-in-out;
 //  }

 .slick-dots li.slick-active button {
   transform: scale(1.3);
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .pricing-section-two {
   background: #f8fafe;
   z-index: 1;
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Footer Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .theme-basic-footer[class*="-five"] .footer-nav-link a,
 .theme-basic-footer[class*="-five"] {
   color: rgba(255, 255, 255, 0.7);
 }

 .theme-basic-footer[class*="-five"] .footer-nav-link a:hover,
 .theme-basic-footer[class*="-five"] .email {
   color: #fff;
 }

 .theme-basic-footer[class*="-five"] .mobile,
 .theme-basic-footer[class*="-five"] .email:hover {
   color: var(--prime-four);
 }


 /*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .block-style-four .list-item li {
   font-size: 24px;
   color: #000;
   margin-bottom: 20px;
   position: relative;
   padding-left: 40px;
 }

 .block-style-four .list-item li:before {
   content: url(../../public/assets/icon/icon_93.svg);
   position: absolute;
   left: 0;
   top: 0;
 }

 .block-style-four .rating li {
   font-size: 17px;
   margin-right: 6px;
   color: var(--prime-ten);
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/

 .feedback-section-ten .shape-one {
   top: 9%;
   left: 51%;
   animation: jumpTwo 5s infinite linear;
 }

 .feedback-section-ten .shape-two {
   bottom: 0;
   left: 0;
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .hero-banner-ten {
   padding: 170px 0 200px;
 }

 .hero-banner-ten .hero-heading {
   font-size: 70px;
   line-height: 1.22em;
   animation: jumpFour 3s infinite linear;
 }

 .hero-banner-ten .hero-heading span {
   color: var(--prime-ten);
 }

 .search-area {
   position: relative;
 }

 .hero-banner-ten .approval-info {
   color: var(--prime-ten);
 }

 .hero-banner-ten .illustration-one {
   position: absolute;
   right: 0;
   bottom: 0;
   width: 25.44%;
   z-index: -1;
 }

 .hero-banner-ten .illustration-two {
   position: absolute;
   left: 0;
   bottom: 0;
   width: 28.05%;
   z-index: -1;
 }


 /*^^^^^^^^^^^^^^^^^^^^^ Feature Section Thirty Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .accordion-style-five .accordion-item {
   background: #ffffff;
   border: none;
   background: #ffffff;
   box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.03);
   border-radius: 10px;
   margin-top: 20px;
   //  animation: prXOne 3s infinite linear;
 }

 .accordion-style-five .accordion-button {
   font-weight: 500;
   font-size: 25px;
   line-height: 1.62em;
   padding: 10px 25px;
   box-shadow: none;
   border-radius: 0;
   color: var(--prime-ten);
   background: transparent;
   transition: all 0.3s ease-in-out;
 }

 .accordion-style-five .accordion-button img {
   width: 25px;
 }

 .accordion-style-five .accordion-item .accordion-button:not(.collapsed) {
   padding-bottom: 12px;
 }

 .accordion-style-five .accordion-body {
   padding: 0 66px 13px;
 }

 .accordion-style-five .accordion-body p {
   font-size: 15px;
   line-height: 25px;
 }

 .accordion-style-five .accordion-button::after {
   content: url(../../public/assets/icon/icon_111.svg);
   background-image: none;
   width: auto;
   height: auto;
 }



 .speciality-block .block-zero,
 .speciality-block .block-one,
 .speciality-block .block-two,
 .speciality-block .block-three {
   background: #fff3c9;
   padding: 10px 20px 20px 20px;
   min-height: 210px;
   text-align: center;

   & .icon {
     height: 60px;
     z-index: -1;
   }

   & h3 {
     color: #000000;
   }

 }

 .speciality-block .block-zero {
   border-radius: 45px 0 0 0;

 }

 .speciality-block .block-one {
   border-radius: 0 45px 0 0;
 }

 .speciality-block .block-two {
   border-radius: 0 0 0 45px;
 }

 .speciality-block .block-three {
   border-radius: 0 0 45px 0;
 }

 .speciality-block .block-two .shape-one {
   bottom: -45px;
   left: -45px;
 }

 .speciality-block .block-wrapper h3 {
   font-size: 25px;
   line-height: 25px;
   margin-bottom: 12px;
 }

 .speciality-block .block-wrapper h3 span {
   font-size: 0.77em;
 }

 .speciality-block .block-wrapper p {
   font-size: 15px;
   line-height: 29px;
   color: rgba(0, 0, 0, 0.7);
 }

 /*^^^^^^^^^^^^^^^^^^^^^ covid Section ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .covid-sec {
   background: #f6f9fb;
 }

 .covid-img {
   height: 300px;
   --color: var(--prime-ten);
   /* the border color */
   --border: 10px;
   /* the border thickness*/
   --offset: 20px;
   /* control the offset*/
   --gap: 5px;
   /* the gap on hover */

   --_c: var(--color) var(--border), #0000 0 calc(100% - var(--border)), var(--color) 0;
   --_o: calc(3*var(--offset));
   padding:
     calc(var(--gap) + var(--border)) calc(var(--gap) + var(--border) + var(--offset)) calc(var(--gap) + var(--border) + var(--offset)) calc(var(--gap) + var(--border));
   background:
     linear-gradient(var(--_c)) var(--_o) var(--_o),
     linear-gradient(90deg, var(--_c)) var(--_o) var(--_o);
   background-size: calc(100% - var(--_o)) calc(100% - var(--_o));
   background-repeat: no-repeat;
   filter: grayscale(.4);
   transition: .5s;
   cursor: pointer;
 }

 img:hover {
   background-position: 0px 0px;
   background-size: calc(100% - var(--offset)) calc(100% - var(--offset));
   filter: grayscale(0);
 }

 .covid-sec .content-sec h5 {
   color: var(--prime-ten);
   font-weight: 500;
 }

 .covid-sec .content-sec p {
   line-height: 1.6;
   font-size: 17px;
 }

 .content-sec .pt-15 p {
   font-size: 26px;
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .feedback-section-eleven {
   background: #f6f9fb;
 }

 .feedback-section-eleven .inner-content {
   width: 124vw;
   transform: translateX(-12vw);
 }

 .feedback-section-eleven .slick-dots {
   text-align: center;
   margin-top: 20px;
 }

 .feedback-section-eleven .slick-dots li button {
   width: 10px;
   height: 10px;
   background: rgba(0, 0, 0, 0.1);
 }

 .feedback-section-eleven .slick-dots li.slick-active button {
   background: var(--prime-ten);
   transform: scale(1);
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Footer Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .footer-style-eleven {
   padding: 50px 0 0;
   background: var(--prime-ten);
 }

 .theme-basic-footer.footer-style-eleven .footer-title {
   color: #fff;
   font-size: 28px;
   text-transform: uppercase;
   letter-spacing: 1px;
   font-weight: 500 !important;
 }

 .footer-style-eleven .footer-nav-link a:hover {
   color: var(--prime-eight);
 }

 .footer-style-eleven .form-widget form input {
   border-radius: 4px;
 }

 .footer-style-eleven .form-widget form button {
   border-radius: 6px;
 }

 .footer-style-eleven .bottom-footer {
   border-top: 1px solid rgb(255 255 255 / 63%);
   padding: 10px 0;
   // margin-top: 60px;
 }

 .footer-style-eleven .bottom-footer .footer-nav a {
   font-size: 15px;
   font-weight: 500;
   color: #000;
   margin-right: 22px;
 }

 .footer-style-eleven .bottom-footer .footer-nav a:hover {
   text-decoration: underline;
 }

 .footer-style-eleven .bottom-footer .copyright {
   font-size: 13px;
   color: #fff;
 }

 .footer-style-eleven .bottom-footer .social-icon a {
   color: #333333;
   transition: all 0.3s ease-in-out;
 }

 .footer-style-eleven .bottom-footer .social-icon li {
   width: 30px;
   height: 30px;
   background: #fff;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: center;
   margin-left: 10px;
 }

 .footer-style-eleven .bottom-footer .social-icon a:hover {
   color: var(--prime-two);
 }

 .footer-style-eleven .shape-one {
   left: 0;
   top: 45%;
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty One ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .fancy-feature-fiftyOne h6 {
   font-size: 20px;
   color: var(--prime-ten);
 }

 .fancy-feature-fiftyOne .text-lg {
   line-height: 2em;
   font-size: 23px;
 }

 .fancy-feature-fiftyOne .shape-one {
   bottom: 0;
   left: 15%;
 }

 .fancy-feature-fiftyOne .shape-two {
   top: 4%;
   right: 17%;
 }

 .fancy-feature-fiftyOne .shape-three {
   bottom: -30%;
   left: 5%;
 }

 .fancy-feature-fiftyOne {
   .hero-heading {
     font-size: 55px;
     line-height: 1.22em;
     color: var(--prime-ten);
   }
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Fancy Short Banner Sixteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .fancy-short-banner-sixteen .bg-wrapper {
   background: #1f1f1f;
   border-radius: 40px;
 }

 .fancy-short-banner-sixteen .main-title {
   font-size: 42px;
   line-height: 1.16em;
 }

 .fancy-short-banner-sixteen .sc-title {
   color: var(--prime-ten);
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Contact Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .address-block-two .icon {
   width: 80px;
   height: 80px;
   background: #000;
 }

 .address-block-two .icon.border {
   border: 1px solid #e6e6e6;
   background: transparent;
 }

 .address-block-two .title {
   font-size: 24px;
   padding: 20px 0 0;
 }

 .address-block-two p {
   font-size: 19px;
   line-height: 1.65em;
   color: #878787;
 }

 .address-block-two p .call {
   font-size: 22px;
   color: var(--prime-ten);
 }

 .address-block-two p .call:hover {
   text-decoration: underline;
 }

 .address-block-two p .webaddress {
   color: var(--heading);
   text-decoration: underline;
 }

 .map-area-two .mapouter {
   height: 400px;
   width: 100%;
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Contact Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
 .contact-section-four .form-wrapper {
   background: #000;
 }

 .form-style-five {
   max-width: 660px;
   padding-left: 100px;
 }

 .form-style-five .form-title {
   font-size: 62px;
   color: var(--prime-ten);
 }

 .form-style-five label {
   font-size: 14px;
   color: rgba(255, 255, 255, 0.5);
   margin-bottom: 5px;
 }

 .form-style-five .input-group-meta input,
 .form-style-five .input-group-meta textarea {
   display: block;
   width: 100% !important;
   max-width: 100%;
   border: none;
   border-radius: 8px;
   background: rgba(255, 255, 255, 0.15);
   color: #fff;
   padding: 0 20px;
   font-size: 16px;
 }

 .form-style-five .input-group-meta input {
   height: 60px;
 }

 .form-style-five .input-group-meta textarea {
   min-height: 165px;
   padding: 15px 20px;
 }

 .form-style-five ::-webkit-input-placeholder {
   color: #fff;
 }

 .form-style-five :-ms-input-placeholder {
   color: #fff;
 }

 .form-style-five ::placeholder {
   color: #fff;
 }

 .form-style-five [class*="btn-"] {
   font-size: 14px;
   letter-spacing: 0.88px;
 }

 .form-style-five [class*="btn-"]:hover {
   background: #fff; //var(--prime-eight);
   color: #000;
 }

 /*^^^^^^^^^^^^^^^^^^^^^ Feature Section Fifty Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
 @keyframes ripple {
   0% {
     box-shadow: 0 0 0 0.1rem rgba(255, 197, 49, 0.1);
   }

   100% {
     box-shadow: 0 0 0 0.4rem rgba(255, 197, 49, 0.3);
   }
 }

 // ########################################
 .portfolio-block-five {
   //  -webkit-box-shadow: -2px 3px 3px 1px rgba(189, 189, 189, 0.75);
   //  -moz-box-shadow: -2px 3px 3px 1px rgba(189, 189, 189, 0.75);
   //  box-shadow: -2px 3px 3px 1px rgba(189, 189, 189, 0.75);
 }



 .blog-card {
   display: block;
   position: relative;
   height: 350px;
   margin: auto;
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.85);
   transition: all 450ms ease-out 0s;
   overflow: hidden;

   .blog-img {
     position: absolute;
     min-width: 100%;
     min-height: 100%;
     height: 100%;
     top: -9999px;
     left: -9999px;
     right: -9999px;
     bottom: -9999px;
     margin: auto;
     overflow: hidden;
     max-width: none !important;
   }

   &:hover {
     box-shadow: 0 2px 35px rgba(#000, 0.85);

     .text-overlay {
       background: rgba(#fff, 0.8);
       height: 50%;
       top: 50%;
       transition: all 450ms ease-in-out 0s;
     }

     p {
       height: 60px;
       transition: all 350ms ease-in-out 0s;

       a {
         visibility: visible;
       }
     }
   }

   .text-overlay {
     position: relative;
     background: rgba(#fff, 0.6);
     width: 100%;
     height: 40%;
     top: 60%;
     box-shadow: 0 -2px 12px rgba(#000, 0.3);
     padding: 10px 12px;
     overflow: hidden;
     transition: all 450ms ease-in-out 0s;
     cursor: pointer;

     h2 {
       color: rgba(#000, 0.85);
       letter-spacing: 0.0225em;
       width: auto;
       margin: 0;
       font-size: 1.5em;
       line-clamp: 2;
     }
   }
 }

 .blog-card-title {
   font-size: 13px;
   font-weight: 500;
   font-family: cursive;
   z-index: 1;
   position: relative;
   background-color: var(--prime-ten) !important;
   color: #fff;
   display: flex;
   flex-direction: row;
   justify-content: center;
   padding: 5px;

   display: -webkit-box;
   -webkit-line-clamp: 2;
   /* Limit to two lines */
   -webkit-box-orient: vertical;
   overflow: hidden;
 }

 .blog-card-date {
   font-size: 13px;
   font-weight: 600;
   z-index: 1;
   position: absolute;
   top: 0;
   right: 0;
   color: #fff;
   background-color: var(--prime-ten) !important;
   padding: 4px;
 }

 .standard-input {
   position: absolute;
   height: 100px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   background: var(--prime-ten);
   width: 30vw;
   left: 0;
   right: 0;
 }

 .standard-input.left {
   border-radius: 20px 0 0 20px;
   margin-right: 10px;
 }

 .standard-input.right {
   border-radius: 0 20px 20px 0;
   margin-left: 10px;
 }

 .standard-input input {
   color: var(--main-white);
   height: 2rem;
   border: none;
   background-color: transparent;
   margin: 15px 40px 0 40px;
   font-size: 17px;
   transition: 0.1s var(--main-ease);
   outline: none;
   box-sizing: border-box;
 }

 .standard-input label {
   position: absolute;
   top: 50%;
   left: 25%;
   transform: translateY(-50%);
   text-align: center;
   transition: .4s var(--main-ease);
   font-size: 1.2rem;
   pointer-events: none;
   padding: 0 5px;
   color: #ffffff;
   font-weight: 500;
 }

 .standard-input input:not(:placeholder-shown)~label,
 .standard-input input:focus~label {
   top: 20px;
   left: 5%;
   font-size: 1.2rem;
   ;
   font-weight: 600;
 }

 .standard-input input:focus {
   border-bottom: 2px solid var(--main-white);
 }

 // .standard-input input:focus ~ label {
 //   color: var(--main-blue) !important;
 // }
 .exchange-route {
   width: inherit;
   height: inherit;
   background: #fff;
   cursor: pointer;
   transition: box-shadow .3s;
   border-radius: 50%;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-content: center;
 }

 .exchange-route:hover {
   background: var(--prime-ten);
   box-shadow: 0 0 11px rgba(33, 33, 33, .2);
 }

 .exchange-route:hover .exchange-route-svg {
   fill: #fff;
 }

 .exchange-route-svg {
   fill: var(--prime-ten)
 }

 .exchange-route-container {
   position: absolute;
   left: 50%;
   top: 28px;
   transform: translateX(-50%);
   z-index: 2;
   width: 55px;
   height: 45px;
 }

 .flight-svg {
   fill: #fff;
 }

 .flight-svg-list {
   fill: var(--prime-ten)
 }

 #nav-search-result.search-results {
   left: unset !important;
   max-height: 350px !important;
 }

 #nav-search-result a:hover {
   color: unset;
 }


 #nav-search-result.search-results li.airport-name {
   padding: 7px 0px 7px 22px;
 }

 .search-results {
   position: absolute;
   top: 85%;
   left: 5% !important;
   width: 700px;
   max-height: 200px;
   background-color: #fff;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   overflow: auto;
   z-index: 1000 !important;
   border: 1px solid #c6c6c6;

   .dropdownheader {
     border: 1px solid #bfdef6;
     cursor: default;
     color: #17639f;
     background: #ecf5fc;
     font-weight: 700;
   }

   .padding-bottom5 {
     padding-bottom: 5px;
   }

   .padding-left10 {
     padding-left: 10px;
   }

   .padding-left5 {
     padding-left: 5px;
   }

   .padding-top5 {
     padding-top: 5px;
   }
 }

 .search-results ul {
   list-style-type: none;
   padding: 0;
   margin: 0;
 }

 .search-results li,
 .search-results li.airport-name {
   padding: 5px;
   font-size: 12px !important;
   cursor: pointer;
 }

 .search-results .airport-name:hover {
   background-color: #1990ff !important;
   color: #fff;
   //  font-weight: 600;

   .flight-svg-list {
     fill: #fff !important;
   }
 }

 .search-results .airport-name {
   padding: 10px;
   cursor: pointer;
   font-weight: 500;
 }

 .search-results li:last-child {
   border-bottom: none;
 }

 /* Positioning for left side */
 .search-results.left {
   left: 0;
 }

 /* Positioning for right side */
 .search-results.right {
   right: 0;
 }

 .guide-body p {
   font-size: 18px;
   line-height: 28px;
   padding-top: 5px;
 }

 //  .slick-slider.slick-initialized button {
 //    display: none !important;
 //  }
 //  .slick-slide{
 //   // width: auto !important;
 //  }

 .newsletter {
   color: #fff;
   font-weight: 400 !important;
 }

 .typed-cursor.typed-cursor--blink {
   display: none !important;
 }

 .main-title {
   color: var(--prime-ten);
 }

 .social-icon {
   fill: var(--prime-ten);
 }

 //////////// Gen card ##################
 .gen-guide-card {
   margin-right: 20px;
   margin-right: 0px;

   .card {
     padding: 1px;
     padding: 10px;
     border: none;
     overflow: hidden;

     &:hover {
       box-shadow: 0 0 11px rgba(33, 33, 33, .2);

       img {
         transform: scale(1.1);
       }
     }
   }

   .inner-card {
     background-color: #fff;
     padding: 15px;
     //  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
     box-shadow: 0 0 15px -7px #014073;
   }

   .img-wrapper {
     width: 100%;
     height: 200;
     margin-bottom: 10px;
     overflow: hidden;
   }

   .img-wrapper img {
     width: 100%;
     height: 100%;
     object-fit: cover;
     object-position: center;
     transition: 0.5s;
   }

   .content {
     margin-bottom: 20px;
     height: 100px;
   }

   .content h1 {
     font-weight: 700;
     font-size: 22px;
     margin-bottom: 10px;
     //  color: #444;
     text-align: center;
   }

   .content p p {
     font-size: 14px;
     line-height: 1.3;
     color: #777;
     font-family: revert;
     font-weight: 600;
   }

   .btn-wrapper {
     display: block;
     text-align: center;
     margin-top: 40px;
   }

   .view-btn {
     border: none;
     background-color: var(--prime-ten);
     color: #fff;
     font-size: 16px;
     cursor: pointer;
     width: 50%;
     height: 25px;
   }

   .view-btn:hover {
     box-shadow: 0 3px 6px rgba(0, 0, 0, .4);
   }
 }


 .inner-card {
   --c: var(--prime-ten);
   /* the border color */
   --b: 8px;
   /* the border thickness*/
   --g: 2px;
   /* the gap on hover */

   padding: calc(var(--g) + var(--b));
   --_g: #0000 25%, var(--c) 0;
   background:
     conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
     conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0 var(--_i, 200%)/var(--_i, var(--b)) 200% no-repeat;
   transition: .5s, background-position .5s .5s;
   cursor: pointer;
 }

 .inner-card:hover {
   --_i: 100%;
   transition: .4s, background-size .4s .4s;

   .content h1 {
     color: var(--prime-ten);
     transition: .5s ease-in-out;
   }
 }

 //######################### home page Blog Start ###################
 .blog-carousal-section .ribbon {
   position: relative;
   margin: 0 auto 20px;
   padding: 15px 70px;
   text-align: center;
   font-size: 22px;
   background-color: var(--prime-ten);
   color: #ffffff;
 }

 .blog-carousal-section .ribbon::before,
 .blog-carousal-section .ribbon::after {
   content: '';
   width: 80px;
   height: 100%;
   background-color: #0d6efd96;

   /* position ribbon ends behind and slightly lower */
   position: absolute;
   z-index: -1;
   top: 20px;

   /* clip ribbon end shape */
   clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 25% 50%);

   /* draw and position the folded ribbon bit */
   background-image: linear-gradient(45deg, transparent 50%, #1b4b93bf 50%);
   background-size: 20px 20px;
   background-repeat: no-repeat;
   background-position: bottom right;
 }

 .blog-carousal-section .ribbon::before {
   left: -60px;
 }

 .blog-carousal-section .ribbon::after {
   right: -60px;
   transform: scaleX(-1);
   /* flip horizontally */
 }

 .blog-carousal-section img {
   max-width: 100%;
   display: block;
   object-fit: cover;
 }


 .blog-carousal-section .head {
   display: flex;
 }

 #pagination {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   padding-top: 10px;
   padding-bottom: 10px;

   a:hover {
     cursor: pointer;
   }

   ul {
     list-style: none;
     padding: 0;
     margin: 0;
     display: flex;
   }

   ul li {
     color: #fff;
     display: flex;
   }

   ul li button {
     // background-color: #444b6e;
     padding: 5px 10px;
     border: 1px solid var(--prime-ten);
     border-right: 0;
     color: var(--prime-ten);
   }

   ul li.active button {
     background-color: var(--prime-ten);
     color: #fff;
   }

   ul li:first-child button {
     border-radius: 5px 0 0 5px;
   }

   ul li:last-child button {
     border-radius: 0 5px 5px 0;
     border-right: 1px solid var(--prime-ten);
   }
 }

 //######################### home page Blog END ###################
 // ######################## Detail blog Start ####################
 .detail-blog-sec {
   background-color: #fff;

   .guideline-details {
     h5 {
       font-size: 17px;
       color: #014073;
       font-family: system-ui; //"Poppins", sans-serif;
       line-height: 20px;
       font-weight: 900;
     }

     p {
       font-family: "Lato", sans-serif;
       margin: 0;
       padding: 0;
       color: var(--paragraph-color);
       font-size: 18px;
       font-weight: 300;
       line-height: 30px;
     }

     td {
       font-size: 15px;
     }
   }

   .bnr {
     margin-left: calc(50% - 50vw);
     width: 100vw;
     height: 62.5vmin;
     min-height: 15em;
     background-attachment: fixed;
     background-blend-mode: multiply;
   }

   .head {

     h1,
     h2,
     h3,
     h4,
     h5,
     h6 {
       font-weight: 700;
       color: var(--prime-ten);
       text-align: center;
       // font-size: 45px;
     }
   }

   .blog-content {
     br {
       display: none !important;
     }

     p {
       font-size: 16px;
       line-height: 26px;
       margin-top: 10px;
       font-family: "Lato", sans-serif;
       color: var(--paragraph-color);
     }
   }

   .more-blogs {
     text-align: center;
     color: var(--prime-ten);
     color: var(--text-color);
   }

   .related-guideline h2 {
     font-size: 25px;
     padding: 20px 31px 20px 30px;
     color: #014073;
     text-transform: uppercase;
     letter-spacing: 1.1px;
     text-decoration: underline;
   }

   .related-guideline {
     .related-guide-card {
       border: 1px solid #014073;
       ;
       border-radius: 10px;
       padding: 15px;
       transition: all .4s ease-in;
       text-align: center;
       margin: 15px 0px;

       a:hover {
         color: var(--prime-ten);
       }

       & .quick-view {
         font-size: 16px;
         border: 1px solid #014073;
         padding: 10px 20px;
         color: #014073;
         color: var(--main-theme-color-one);
         border-radius: 4px;
         display: inline-flex;
         // transition: all .2s ease-in;
         // -webkit-transition: all .4s ease-in;
       }

       & h4 {
         font-weight: 700;
         transition: all .4s ease-in;
       }
     }
   }

   .related-guide-card:hover {
     box-shadow: 0 0 15px -7px #014073;
     border: 1px solid #fff;
     transition: all .4s ease-in;
     color: var(--prime-ten);

     .quick-view {
       border-color: var(--prime-ten);
     }

     & h4 {
       color: var(--prime-ten);
       transition: all .4s ease-in;
     }

     // box-shadow:0 0 0 .6px rgba(33, 33, 33, .2);
   }
 }


 .detail-blog-sec .card,
 .home-page-blog .card {
   display: flex;
   flex-direction: column;
   // width: 340px;
   overflow: hidden;
   box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
   // border-radius: 1em;
   // background: linear-gradient(to right, #FFFFFF, #ECE9E6);
   margin: 10px;
   padding: 0;
 }

 .detail-blog-sec .main-img {
   width: 100%;
   transform: scale(1.1);
   transition: 2s;

   // height: 500px;
   // object-fit: fill;
   &:hover {
     transform: scale(1);
   }
 }

 .detail-blog-sec .card__body,
 .home-page-blog .card__body {
   padding: 1rem;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   min-height: 140px;
   max-height: 140px;
 }

 .detail-blog-sec .tag,
 .home-page-blog .tag {
   align-self: flex-start;
   padding: .25em .75em;
   border-radius: 1em;
   font-size: .95rem;
 }

 .detail-blog-sec .tag+.tag,
 .home-page-blog .tag+.tag {
   margin-left: .5em;
 }

 .detail-blog-sec .tag-blue,
 .home-page-blog .tag-blue {
   background: #56CCF2;
   background: linear-gradient(to bottom, #2F80ED, #56CCF2);
   color: #fafafa;
 }

 .detail-blog-sec .tag-brown,
 .home-page-blog .tag-brown {
   background: #D1913C;
   background: linear-gradient(to bottom, #FFD194, #D1913C);
   color: #fafafa;
 }

 .detail-blog-sec .tag-red,
 .home-page-blog .tag-red {
   background: #cb2d3e;
   background: linear-gradient(to bottom, #ef473a, #cb2d3e);
   color: #fafafa;
 }

 .detail-blog-sec .card__header img,
 .home-page-blog .card__header img {
   transition: 0.5s;
 }

 .detail-blog-sec .card:hover .card__header img,
 .home-page-blog .card:hover .card__header img {
   transform: scale(1.1);
 }

 .detail-blog-sec .card__body h4,
 .home-page-blog .card__body h4 {
   font-size: 14px;
   text-transform: capitalize;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
 }

 .detail-blog-sec .card .read-more,
 .home-page-blog .card .read-more {
   display: flex;
   flex-direction: column;
   justify-content: center;
 }

 .detail-blog-sec .card .read-more a,
 .home-page-blog .card .read-more a {
   background-color: #fff;
   color: var(--prime-ten);
   font-size: 12px;
   cursor: pointer;
   padding: 5px 10px;
   border-radius: 5px;
   border: 2px solid var(--prime-ten);
   transition: .2s ease-in-out;

   &:hover {
     box-shadow: 0 3px 6px rgba(0, 0, 0, .4);
     font-weight: 600;
     background-color: var(--prime-ten);
     color: #fff;
   }
 }

 .detail-blog-sec .card__footer,
 .home-page-blog .card__footer {
   display: flex;
   padding: 0 0.5rem 0.5rem 0.5rem;
   margin-top: auto;
   justify-content: space-between;
   justify-content: center;
 }

 .detail-blog-sec .user,
 .home-page-blog .user {
   display: flex;
   gap: .5rem;
 }

 .detail-blog-sec .user__image,
 .home-page-blog .user__image {
   border-radius: 50%;
   width: 40px;
   height: 40px;
 }

 .detail-blog-sec .user__info h5,
 .home-page-blog .user__info h5 {
   font-size: 14px;
   margin: 0;
 }

 .detail-blog-sec .user__info>small,
 .home-page-blog .user__info>small {
   color: #666;
   font-size: 13px;
 }


 // ######################## Detail blog END ####################
 // ######################### Airport search Start ####################
 .truncated-text {
   white-space: nowrap;
   /* Prevents the text from wrapping to the next line */
   overflow: hidden;
   /* Hides the overflowed text */
   text-overflow: ellipsis;
   /* Adds '...' at the end of the truncated text */
   max-width: 100%;
   /* Ensure it adjusts according to its container */
   display: block;
   /* Ensures that it behaves as a block for 'Link' */
 }

 .airport-search-result {
   .blog-head {
     position: relative;
     margin-bottom: 20px;
     color: var(--prime-ten);
     text-align: center;

     &:before,
     &:after {
       position: absolute;
       content: '';
       border-bottom: 1px solid var(--prime-ten);
       border-radius: 1em;
       bottom: 0;
       transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
     }

     &:before {
       width: 100px;
       transform-origin: left;
     }

     &:after {
       // width: 82%;
       left: 1em;
       transform: translateX(110%);
     }

     &:hover:before {
       transform: scaleX(0.3);
     }

     &:hover:after {
       transform: translateX(0);
     }
   }

   .blog-head:after {
     content: "";
     // position: absolute;
     // left: 10px;
     // bottom: 0;
     // width: 100px;
     // height: 2px;
   }

   .airport-name {
     color: #014073;

     & a:hover {
       color: var(--prime-five);
       transition: all .3s;
     }
   }
 }

 // ######################### Airport search END ####################

 .trvl-g-b {
   .slider .slick-list .slick-track {
     .slick-slider {
       width: 500px !important;
     }
   }
 }

 .contact-us .title-style-fourteen.text-center {
   background-color: aliceblue;
   padding: 50px 70px;
   border-radius: 6em;

   >p {
     text-align: justify;
   }
 }

 .lottie-animation-airport-result {
   width: 300px;
   position: absolute;
 }

 .left {
   left: 0;
 }

 .right {
   right: 0;
 }

 // ####################################
 .blog-image {
   overflow: hidden;
 }

 /* Ensure col-6 handles overflow */
 .col-6 {
   overflow-wrap: break-word;
   /* Ensures text wraps within the container */
 }

 /* Ensure text wraps within the row */
 .row {
   word-wrap: break-word;
   /* Wraps long words */
   word-break: break-word;
   /* Breaks words at arbitrary points to prevent overflow */
 }

 /* Ensure text in span wraps correctly */
 .row span {
   display: block;
   /* Make sure the span takes full width */
   white-space: normal;
   /* Allow text to wrap to the next line */
   overflow-wrap: break-word;
   /* Wraps long words */
   word-break: break-word;
   /* Breaks words at arbitrary points to prevent overflow */
 }