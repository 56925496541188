.navbar-toggler {
  width: 44px;
  height: 38px;
  padding: 0;
  box-shadow: none;
  position: relative;
  z-index: 99;
  border: none;
  background: var(--prime-one);
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler::before,
.navbar-toggler::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 9px;
  width: 26px;
  height: 2px;
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background: #fff;
}
.navbar-toggler span {
  position: absolute;
  left: 9px;
  overflow: hidden;
  width: 26px;
  height: 2px;
  margin-top: -1px;
  text-indent: 200%;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  background: #fff;
}
.navbar-toggler::before {
  -webkit-transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
  transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
}
.navbar-toggler::after {
  -webkit-transform: translate3d(0, 8px, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 8px, 0) scale3d(1, 1, 1);
}
.navbar-toggler[aria-expanded="true"] span {
  opacity: 0;
}
.navbar-toggler[aria-expanded="true"]::before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}
.navbar-toggler[aria-expanded="true"]::after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}
.navbar .navbar-nav .nav-link {
  font-weight: 500;
  font-size: 22px;
  line-height: initial;
  color: #fff;
  padding: 13px 0;
  margin: 15px 15px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item:hover .nav-link {
  color: var(--prime-one);
}

/*Menu Eight*/
.theme-menu-eight .navbar .navbar-nav .nav-item.active .nav-link,
.theme-menu-eight .navbar .navbar-nav .nav-item:hover .nav-link,
.theme-menu-eight .navbar .dropdown-menu .dropdown:hover > .dropdown-item,
.theme-menu-eight .navbar .dropdown-menu .dropdown-item.active,
.theme-menu-eight .navbar .dropdown-menu .dropdown-item:hover {
  color: var(--prime-eight);
}
.theme-menu-eight .navbar-toggler {
  background: var(--prime-ten);
}
/*Menu Ten*/
.theme-menu-ten .navbar-toggler {
  background: var(--prime-twelve);
}

.navbar .dropdown-menu .dropdown-item {
  font-size: 16px;
  padding: 0 15px;
  letter-spacing: 0px;
  text-transform: capitalize;
  line-height: 44px;
  color: #000;
  background: transparent;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
  white-space: inherit;
}
.navbar .dropdown-menu .dropdown-item .tag {
  position: absolute;
  background: var(--red-one);
  line-height: 15px;
  color: #fff;
  font-size: 10px;
  letter-spacing: 1px;
  padding: 0 4px;
  text-transform: uppercase;
  top: 0;
  right: 0;
  font-style: normal;
}
.navbar .dropdown-menu .dropdown:hover > .dropdown-item,
.navbar .dropdown-menu .dropdown-item.active,
.navbar .dropdown-menu .dropdown-item:hover {
  color: var(--prime-one);
}

/*---------------- Mega Dropdown ------------------*/
.theme-main-menu .mega-dropdown {
  position: static;
}
.theme-main-menu .mega-dropdown-md .dropdown-menu {
  min-width: 660px;
}
.navbar [class*="mega-dropdown"] .dropdown-menu {
  padding: 0 30px 15px;
  margin: 0;
}
.navbar [class*="mega-dropdown"] .menu-column {
  padding: 15px 0 15px;
}
.navbar [class*="mega-dropdown"] .mega-menu-title {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  display: inline-block;
  position: relative;
  margin: 15px 0 18px;
}
.navbar [class*="mega-dropdown"] .mega-menu-title:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #000;
  left: 0;
  bottom: -5px;
}
.navbar [class*="mega-dropdown"] .dropdown-item {
  line-height: 40px;
  padding: 0;
}
.lang-list.dropdown-menu {
  z-index: 5;
  background-color: #fff !important;
  border-radius: 5px !important;
  display: block;
  right: 0;
  left: 0;
  padding: 10px 5px !important;
  border: none !important;
  top: 100%;
  visibility: hidden;
  transform: translateY(15px);
  opacity: 0;
  min-width: 250px;
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.12);
  margin: 0;
  transform-origin: 0 0;
  transition: all 0.3s ease-in-out;
  height: 300px;
  overflow: auto;
}
.lang-btn{
  color: #fff;
}
// .dropdown:hover .dropdown-menu {
//   display: block !important;
//  }
/*----- For Desktop -------*/
@media screen and (min-width: 992px) {
  .theme-main-menu.theme-menu-ten .navbar{
    position: relative;
    left: 0;
    transform: none;
  }

  
  .navbar .dropdown-menu .dropdown-menu {
    left: calc(100% + 5px);
    top: 0;
    right: auto;
    min-width: 240px;
    box-shadow: 0 20px 30px -10px rgb(0, 0, 0, 0.15);
    transform: translateY(0);
  }
  .navbar .dropdown:hover > .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
/*(min-width: 992px)*/

/*----- For Mobile ----*/
@media screen and (max-width: 991px) {
  .navbar {
    padding: 0;
  }
  .navbar-collapse .logo {
    margin-bottom: 6vh;
  }
  .navbar-collapse {
    position: fixed;
    top: 0;
    background-color: var(--prime-ten);
    left: 0;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    clear: both;
    width: 320px;
    max-width: calc(100vw - 60px);
    z-index: 9999;
    transform: translateX(-100%);
    display: block !important;
    padding: 0px 15px 20px;
    transition: all 0.3s ease-in-out;
  }
  .theme-main-menu .logo img {
    height: 70px !important;
    width: 70px !important;
  }
  .navbar-collapse.show {
    transform: translateX(0);
    box-shadow: 15px 0 25px rgb(35 35 35 / 57%);
  }
  .theme-main-menu .navbar .mega-dropdown {
    position: relative;
  }
  .navbar .navbar-nav .nav-link {
    margin: 0;
    padding: 13px 0;
    font-size: 16px;
  }
  .navbar .dropdown-menu .dropdown-item {
    padding: 0 10px;
    font-size: 15px;
    line-height: 42px;
  }
  .navbar .dropdown-menu .dropdown-item.dropdown-toggle {
    font-weight: 500;
  }
  .navbar .dropdown-submenu .dropdown-toggle.show::after {
    transform: rotate(90deg);
  }
  .navbar .dropdown-menu {
    border: none;
    padding: 0;
    border-radius: 0;
    margin: 0;
    background: #eefbfa;
  }
  .navbar .dropdown-submenu .dropdown-menu {
    margin: 0;
    padding: 0 0 0 10px;
  }
  .navbar [class*="mega-dropdown"] .dropdown-menu {
    padding: 0 15px;
  }
  .theme-main-menu .mega-dropdown-md .dropdown-menu {
    min-width: 100%;
  }
  .navbar [class*="mega-dropdown"] .mega-menu-title {
    margin: 0 0 12px;
  }
  .navbar [class*="mega-dropdown"] .menu-column {
    padding-bottom: 0;
  }
}
/*(max-width: 991px)*/


// #################################
.dropdown-toggle::after {
  display: none;
}

.search-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 10px 20px;
  // background: var(--prime-ten);
  background: #ffffff;
  color:  var(--prime-ten);
  width: 50px;
  transition: 0.4s width ease-out;
}
.search-nav__input {
  width: 0;
  flex: 0;
  padding: 0;
  margin: 0;
  border: none;
  // background: var(--prime-ten);
  // border-bottom: 1px solid #ddd;
  transition: 0.4s width, flex ease-out;
  color: var(--prime-ten);
}
.search-nav--visible {
  // padding: 2px 10px;
  flex: 1 0;
  margin: 0 60px;
  height: 50px;
  text-wrap: nowrap;
  overflow: hidden;
}
.search-nav--visible .search-nav__input {
  margin-right: 6px;
  flex: 1;
  padding: 0px 8px;
}
.search-nav__btn {
  width: 32px;
  height: 32px;
  border: none;
  background-size: 20px;
  cursor: pointer;
}
.search-nav__input:focus {
  border: none;
  // border-bottom: 1px solid;
}
.search-nav__input:focus,
.search-nav__btn:focus {
  outline: none;
}
.search-nav__input::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}
.search-nav__btn .fa-solid{
  font-size: 25px;
}
.dropdown-menu.show{
  opacity: 1;
  visibility: visible;
}
.lang-item{
  cursor: pointer;
}