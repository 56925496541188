@media (min-width: 992px) {
  .container {
    padding-right: 0;
    padding-left: 0;
  }

  .mega-dropdown .col-lg-2 {
    width: 20%;
  }
}

/*(min-width: 992px)*/

@media (min-width: 1400px) {}

/*(min-width: 1400px)*/


/*Between Grid xl*/
@media (min-width: 1200px) and (max-width: 1399px) {
  h1 {
    font-size: 65px;
  }

  h2 {
    font-size: 54px;
  }

  h3,
  .h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 22px;
  }

  .title-style-six .main-title,
  .form-style-five .form-title {
    font-size: 55px;
  }

  .title-style-five .main-title {
    font-size: 58px;
  }

  .title-style-fourteen .main-title {
    font-size: 58px;
  }

  .p-30 {
    padding: 20px;
  }

  .theme-main-menu {
    padding-right: 35px;
    padding-left: 35px;
  }

  .hero-banner-ten .hero-heading {
    font-size: 72px;
  }

  .text-lg {
    font-size: 22px;
  }

  .feedback-section-eleven .inner-content {
    width: 150vw;
    transform: translateX(-25vw);
  }

  .fancy-feature-fiftyOne .shape-two {
    right: 8%;
  }

  .form-style-five {
    max-width: 570px;
    padding-left: 50px;
  }
}

/*(min-width: 1200px) and (max-width: 1399px)*/

@media screen and (min-width: 1200px) {
  .theme-menu-eight .logo {
    min-width: 226px;
  }
}

/*Grid lg Start*/
@media (max-width: 1199px) {
  h4 {
    font-size: 20px;
  }

  .h5,
  h5 {
    font-size: 20px;
  }

  .text-lg {
    font-size: 19px !important;
    line-height: 1.67em !important;
  }

  .fs-20 {
    font-size: 18px;
  }

  .fs-18 {
    font-size: 16px;
  }

  .fs-17 {
    font-size: 15px;
  }

  .p-30 {
    padding: 0;
  }

  body .btn-fourteen {
    line-height: 55px;
    font-size: 16px;
    padding: 0 25px;
  }

  body .btn-ten {
    line-height: 40px;
    font-size: 15px;
  }

  body .btn-four {
    line-height: 48px;
    font-size: 15px;
    padding: 0 35px;
  }

  body .btn-twentyTwo {
    line-height: 50px;
    padding: 0 32px;
  }

  .title-style-six .sc-title {
    font-size: 15px;
  }

  .title-style-fourteen .sc-title {
    font-size: 13px;
    padding-bottom: 10px;
  }

  .form-style-five .form-title {
    font-size: 45px;
  }

  .title-style-one .sc-title {
    font-size: 13px;
  }

  .accordion-style-five .accordion-button {
    font-size: 20px;
    padding: 18px 15px;
  }

  .accordion-style-five .accordion-body p,
  .speciality-block .block-wrapper p {
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: left !important;
  }

  .accordion-style-five .accordion-body {
    padding: 0 20px 5px 57px;
  }

  .speciality-block .block-wrapper h3 {
    font-size: 26px;
    line-height: 1.16em;
  }

  .footer-style-eleven {
    padding: 100px 0 0;
  }

  .footer-style-eleven .bottom-footer {
    padding: 5px 0 20px;
    margin-top: 30px;
  }

  .theme-basic-footer .form-widget form {
    height: 60px;
  }

  .theme-basic-footer .form-widget form button {
    width: 110px;
  }

  .title-style-five .sc-title {
    font-size: 14px;
    padding-bottom: 10px;
  }

  .block-style-four .list-item li {
    font-size: 18px;
  }

  .hvr-shutter-out .img-meta .caption .pj-title {
    font-size: 20px;
  }

  .address-block-two .title {
    font-size: 20px;
    padding: 12px 0 0;
  }

  .address-block-two p {
    font-size: 16px;
  }

  .address-block-two p .call {
    font-size: 20px;
  }

  .address-block-two .icon {
    width: 50px;
    height: 50px;
    padding: 15px;
  }
}

/*(max-width: 1199px)*/

/*Between Grid lg*/
@media (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 55px;
  }

  h2 {
    font-size: 45px;
  }

  h3,
  .h3 {
    font-size: 26px;
  }

  .title-style-six .main-title {
    font-size: 48px;
  }

  .title-style-five .main-title {
    font-size: 50px;
  }

  .title-style-fourteen .main-title {
    font-size: 48px;
  }

  .theme-main-menu {
    padding-right: 20px;
    padding-left: 20px;
  }

  .navbar .navbar-nav .nav-link {
    font-size: 17px;
  }

  .hero-banner-ten {
    padding-top: 200px;
  }

  .hero-banner-ten .hero-heading {
    font-size: 58px;
  }

  .fancy-feature-fiftyOne .shape-one {
    left: 6%;
  }

  .fancy-feature-fiftyOne .shape-two {
    right: 6%;
  }

  .form-style-five {
    max-width: 480px;
    padding-left: 40px;
  }
}

/*(min-width: 992px) and (max-width: 1199px)*/

/*Grid md Start*/
@media (max-width: 991px) {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 35px;
  }

  h3,
  .h3 {
    font-size: 22px;
  }

  .h6,
  h6 {
    font-size: 16px;
  }

  .fs-15 {
    font-size: 14px;
  }

  .title-style-five .main-title {
    font-size: 35px;
  }

  .title-style-six .main-title {
    font-size: 32px;
  }

  .title-style-fourteen .main-title {
    font-size: 40px;
  }

  .title-style-six .sc-title-two {
    font-size: 13px;
    padding-bottom: 5px;
  }

  .theme-main-menu {
    // padding-right: 12px;
    // padding-left: 12px;
  }

  .hero-banner-ten .hero-heading {
    font-size: 35px !important;
  }

  .hero-banner-ten {
    padding-top: 100px !important;
  }

  .theme-main-menu .lang-dropdown:before,
  .feedback-section-ten .shape-one,
  .fancy-feature-fiftyOne .shapes,
  .footer-style-eleven .shape-one {
    display: none !important;
  }

  .theme-basic-footer .footer-title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .theme-basic-footer .footer-nav-link a {
    font-size: 16px;
    line-height: 38px;
  }

  .theme-basic-footer .form-widget form {
    height: 60px;
  }

  .footer-style-eleven .bottom-footer .footer-nav a,
  .footer-style-eleven .bottom-footer .social-icon a {
    margin: 0 10px;
  }

  .fancy-feature-fiftyOne h6 {
    font-size: 18px;
  }

  .form-style-five {
    padding: 0;
    margin: 0 auto;
  }
}

/*(max-width: 991px)*/

/*Between Grid md*/
@media (min-width: 768px) and (max-width: 991px) {
  .feedback-section-eleven .inner-content {
    width: 180vw;
    transform: translateX(-40vw);
  }

  .form-style-five {
    max-width: 696px;
  }
}

/*(min-width: 768px) and (max-width: 991px)*/

/*Grid sm Start*/
@media (max-width: 767px) {
  body .btn-twentyTwo {
    line-height: 50px;
    padding: 0 35px;
  }

  .feedback-section-eleven .inner-content {
    width: 100%;
    transform: none;
  }
}

/*(max-width: 767px)*/

/*Extra Small breakpoints*/
@media (max-width: 575px) {

  .speciality-block .block-zero,
  .speciality-block .block-one,
  .speciality-block .block-two,
  .speciality-block .block-three {
    padding: 35px 15px 20px;
    text-align: center !important;
    border: none !important;
    padding: 20px 10px !important;
    // min-height: 170px !important;
    min-height: 196px !important;

    & .icon {
      height: 40px !important;
    }
  }
}

/*(max-width: 575px)*/


/* MEDIA QUERIES */
@media screen and (max-width: 1285px) {
  .cards-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 900px) {
  .cards-wrapper {
    grid-template-columns: 1fr;
  }

  .info {
    justify-content: center;
  }

  .card-grid-space .num {
    //margin-left: 0;
    //text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .cards-wrapper {
    padding: 4rem 2rem;
  }

  .card {
    // max-width: calc(100vw - 4rem);
    max-width: calc(100vw - 0rem);
  }
}

@media screen and (max-width: 450px) {
  // mobile fone

  .info {
    display: block;
    text-align: center;
  }

  .info h1 {
    margin: 0;
  }

  // .hero-banner-ten .hero-heading {
  //   font-size: xx-large !important;
  // }
  .text-lg {
    font-size: 0.7rem !important;
  }

  #nav-search-result.search-results {
    left: 0% !important;
    width: 400px;
  }

  .search-nav--visible {
    margin: 0px 4px !important;
    height: 40px !important;
  }

  .title-style-one .sc-title {
    font-size: 11px !important;
  }

  .title-style-one .main-title {
    font-size: x-large !important;
  }

  .guide-body p {
    font-size: 12px !important;
    line-height: 20px !important;
  }

  // .accordion-style-five .accordion-button{
  //   font-size: 20px !important;
  // }
  .accordion-style-five .accordion-body p {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .blog-carousal-section .ribbon {
    margin: 0px auto 0px !important;
    padding: 10px 30px !important;
    font-size: 15px !important;
  }

  .hero-banner-ten .illustration-one,
  .hero-banner-ten .illustration-two {
    // width: 40% !important;
    // height: 40% !important;
    display: none !important;
  }

  .search-area {
    width: 100% !important;
  }

  .standard-input.left,
  .standard-input.right {
    border-radius: 0 !important;
    margin: 7px 0px !important;
  }

  .exchange-route-container {
    top: 39.5% !important;
    width: 40px !important;
    height: 35px !important;
  }

  .exchange-route {
    border-radius: unset !important;
  }

  .standard-input label {
    font-size: 1em !important;
  }

  // .speciality-block .block-zero{
  //   text-align: center !important;
  //   border: none !important;
  //   padding: 20px 10px !important;
  // }
  .speciality-block .block-wrapper h3 {
    font-size: 21px !important;
    line-height: 20px !important;
    margin-bottom: 7px !important;
  }

  .speciality-block .block-wrapper p {
    font-size: 12px !important;
    line-height: 17px !important;
    text-align: left !important;
  }

  .search-results.left {
    top: 70px !important;
    left: 2% !important;
    width: 96%;
  }

  .to-search-hide {
    visibility: hidden !important;
  }

  .fancy-feature-fiftyOne .hero-heading {
    font-size: 25px !important;
    line-height: 1.2em !important;
  }

  .accordion-style-five .accordion-button {
    padding: 10px 25px 10px 15px !important;
  }

  .accordion-style-five .accordion-body {
    padding: 0px 20px 1px 38px !important;
    text-align: left !important;
  }

  .accordion-style-five .accordion-body p {
    font-size: 13px !important;
  }

  .navbar .dropdown-menu {
    height: 300px !important;
    transform: translateY(7px);
  }
}

@media (max-width: 400px) {
  .blog-carousal-section .ribbon {
    font-size: 1.5rem;
  }

  .hero-banner-ten .hero-heading {
    font-size: 30px !important;
  }

  .speciality-block .block-wrapper h3 {
    font-size: 18px !important;
    line-height: 18px !important;
    margin-bottom: 10px !important;
  }

  .hero-banner-ten {
    padding: 100px 0 115px !important;
  }

  .accordion-style-five .accordion-button {
    font-size: 15px !important;
  }

  .detail-blog-sec .main-img {
    height: 350px !important;
  }

  .lottie-animation-airport-result {
    display: none !important;
  }
}


@media (max-width: 780px) {
  .detail-blog-sec .card__body h4 {
    font-size: 12px !important;
  }

  .card {
    flex-basis: 50%;
  }

  .title {
    font-size: 30px;
  }

  .standard-input {
    position: relative !important;
    width: 100% !important;
    height: 80px !important;
  }

  .detail-blog-sec .card__body {
    min-height: 130px !important;
  }

  .detail-blog-sec {
    padding-top: 70px !important;
  }

  .detail-blog-sec .related-guideline h2 {
    font-size: 20px !important;
    padding: 10px 10px !important;
    margin-top: 40px !important;
  }

  .detail-blog-sec .main-img {
    height: 350px !important;
  }

  .detail-blog-sec .tag,
  .home-page-blog .tag {
    font-size: 10px !important;
  }

  .gen-guide-card .content h1 {
    font-size: 15px !important;
  }

  .gen-guide-card .card {
    padding: 1px !important;
  }

  .gen-guide-card .inner-card {
    padding: 0px !important;
  }

  .gen-guide-card .content {
    padding: 0px 5px !important;
  }

  .btn-wrapper {
    margin: 0px 0px 10px 0px !important;
  }

  .gen-guide-card .content p p {
    font-size: 13px !important;
  }

  .inner-card:hover {
    --_i: 0px !important;
  }

  .lottie-animation-airport-result {
    width: 140px !important;
  }

  .hero-banner-ten {
    padding: 110px 0 100px !important;
  }
}

@media (max-width: 1024px) {
  .home-page-blog .card {
    min-height: 280px !important;
  }

  .detail-blog-sec .card {
    min-height: 250px !important;
  }

  .theme-main-menu .lang-dropdown button {
    font-size: 17px !important;
  }

  .lang-icon {
    width: 19px !important;
    height: 19px !important;
  }
}

@media (max-width: 500px) {
  .card {
    flex-basis: 5100%;
  }

  .box .close-btn {
    margin-left: 0;
    left: 80%;
    top: -12%;
  }

  .detail-blog-sec .main-img {
    height: 200px !important;
  }

  .detail-blog-sec .related-guideline h2 {
    font-size: 18px !important;
    padding: 10px 10px !important;
  }

  .detail-blog-sec h2 {
    font-weight: 600 !important;
    font-size: 27px !important;
  }

  .detail-blog-sec p {
    font-size: 12px !important;
  }

  .detail-blog-sec .sc-title-two:before,
  .title-style-five .sc-title-two:before {
    top: 10px !important;
  }

  .detail-blog-sec .sc-title-two,
  .title-style-five .sc-title-two {
    font-size: 14px !important;
  }

  .theme-basic-footer.footer-style-eleven .footer-title {
    font-size: 22px !important;
  }

  .theme-basic-footer .footer-nav-link a {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .scroll-top {
    width: 50px !important;
    height: 50px !important;
    line-height: 48px !important;
    bottom: 33px !important;
    right: 24px !important;
  }

  .footer-style-eleven .bottom-footer .copyright {
    font-size: 8px !important;
    padding-top: 8px !important;
  }

  .fs-14 {
    font-size: 9px !important;
  }

  .standard-input input {
    font-size: 12px !important;
  }

  .search-results li {
    font-size: 10px !important;
  }

  .gen-guide-card .content h1 {
    font-size: 14px !important;
  }

  .gen-guide-card .content p p {
    font-size: 12px !important;
  }

  .airport-search-result .blog-head {
    font-size: 15px !important;
  }

  .btn-wrapper {
    margin-top: 10px !important;
  }

  .airport-search-result .airport-name {
    font-size: 17px !important;
  }

  .full-page-loader {
    width: 500px !important;
    height: 400px !important;
  }

  .lottie-animation-airport-result {
    width: 80px !important;
    padding-top: 40px !important;
  }

  .hero-banner-ten {
    padding: 90px 0 20px !important;
  }

  .standard-input input:focus {
    border-bottom: 1px solid var(--main-white) !important;
  }

  .standard-input {
    height: 70px !important;
  }

  .standard-input input {
    height: 30px !important;
    margin: 25px 40px 0 40px !important;
  }
}

@media (max-width: 320px) {

  .speciality-block .block-zero,
  .speciality-block .block-one,
  .speciality-block .block-two,
  .speciality-block .block-three {
    padding: 0px !important;
    min-height: 170px !important;
  }

  .detail-blog-sec .main-img {
    height: 300px !important;
  }

  .detail-blog-sec .related-guideline h2 {
    font-size: 17px !important;
    padding: 10px 10px !important;
  }
}